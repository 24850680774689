import { markRaw, type Ref, ref } from 'vue';
import { generateUUID } from '@/shared/model/utils';
import NotifyComponent from '../ui/template/DefaultNotify.vue';
import type { NotifyData, NotifyItem } from './NotifyTypes';

const DEFAULT_NOTIFY_COMPONENT = NotifyComponent;

const items = ref<NotifyItem[]>([]);

export type NotificationMethodType = ReturnType<typeof useNotificationStore>;

export const useNotificationStore = () => {
    const remove = (id: string) => {
        const item = items.value.find(item => item.id === id);
        if (item?.timerId) {
            clearTimeout(item.timerId);
        }
        items.value = items.value.filter(it => it !== item);
    };

    const findCopy = (item: NotifyItem) => {
        return items.value.find(it => {
            const props = it.props ?? {};
            const sameType = item.props?.type === props.type;
            const sameText = item.props?.text === props.text;
            const sameTitle = item.props?.title === props.title;
            return sameType && sameText && sameTitle && item.component === DEFAULT_NOTIFY_COMPONENT;
        });
    };

    const add = ({ component = DEFAULT_NOTIFY_COMPONENT, props = {}, ...options }: NotifyData) => {
        const id = generateUUID();

        const duration = options.duration ?? 5000;

        const _component = component ?? DEFAULT_NOTIFY_COMPONENT;
        const item: NotifyItem = {
            id,
            component: markRaw(_component),
            props,
            ...options,
            duration
        };

        const copy = findCopy(item);

        if (copy) {
            return;
        }

        if (duration) {
            item.timerId = setTimeout(() => {
                remove(id);
            }, duration) as unknown as number;
        }

        items.value.push(item);
        if (items.value.length > 6) {
            items.value.shift();
        }

        const update = (props: Record<string, unknown>, merge = true) => {
            if (merge) {
                item.props = { ...item.props, ...props };
            } else {
                item.props = props;
            }
        };

        return {
            update,
            reject: () => update({ error: true }),
            close: () => remove(id)
        };
    };

    const clear = () => {
        items.value = [];
    };

    return {
        items: items as Readonly<Ref<NotifyItem[]>>,
        add,
        clear,
        remove
    };
};
