import { computed, type MaybeRefOrGetter, reactive, ref, toValue, watch } from 'vue';
import { CanceledError } from 'axios';
import { dayjs } from '@/shared/lib/dayjs';
import * as yup from 'yup';
import type { Placement, PlacementId } from '@/entities/Placement';
import { type Keyword, type KeywordId } from '@/entities/Keywords';
import { numberFormatCurrency, captureError } from '@/shared/model/utils';
import { BookingError, type BookingId, type BookingKeywordView, BookingService } from '@/entities/Booking';
import type { BookingAvailableKeywordsParams } from '@/entities/Booking/api/BookingService';

interface KeywordViewParams {
    year: MaybeRefOrGetter<string>;
    dates: MaybeRefOrGetter<string[]>;
    keywords: MaybeRefOrGetter<Keyword[]>;
    placement: MaybeRefOrGetter<Placement | undefined>;
    bookingId?: MaybeRefOrGetter<BookingId | undefined>;
}

export const keywordViewPayloadSchema: yup.ObjectSchema<BookingAvailableKeywordsParams> = yup.object({
    year: yup.string().required(),
    placementId: yup.number<PlacementId>().required(),
    keywordIds: yup.array(yup.number<KeywordId>().required()).min(1).required(),
    excludeBookingId: yup.number<BookingId>().optional(),
    currentPage: yup.number().required(),
    itemsPerPage: yup.number().required()
});

export const useBookingKeywordsViews = ({ keywords, placement, bookingId, dates, year }: KeywordViewParams) => {
    const loading = ref(false);
    const keywordViewMap = reactive(new Map<KeywordId, BookingKeywordView>());
    let abortController = new AbortController();

    const months = computed(() => toValue(dates).map(date => dayjs(date).month() + 1));
    const keywordIds = computed(() => toValue(keywords).map(keyword => keyword.id));

    const getKeywordAverage = (keyword: Keyword) => {
        return keywordViewMap.get(keyword.id)?.averageViews ?? null;
    };

    const getKeywordPrice = (keyword: Keyword) => {
        const price = keywordViewMap.get(keyword.id)?.price;
        return price ? `${numberFormatCurrency(price / 100)} руб` : null;
    };

    const isKeywordUsed = (keyword: Keyword) => {
        const busyMonths = keywordViewMap.get(keyword.id)?.busyMonths;
        if (!busyMonths || busyMonths.length === 0) return false;
        return months.value.some(month => busyMonths.includes(month));
    };

    const keywordsPrice = computed(() => {
        return keywordIds.value
            .map(keywordId => keywordViewMap.get(keywordId)?.price)
            .filter((price): price is number => price !== undefined)
            .reduce((total, price) => total + price, 0);
    });

    const getBookingAvailablePayload = () => {
        return {
            year: toValue(year),
            placementId: toValue(placement)?.id,
            keywordIds: keywordIds.value,
            excludeBookingId: toValue(bookingId),
            currentPage: 1,
            itemsPerPage: keywordIds.value.length
        };
    };

    const getBookingAvailableKeywords = async () => {
        try {
            loading.value = true;
            const payload = await keywordViewPayloadSchema.validate(getBookingAvailablePayload());

            abortController.abort();
            abortController = new AbortController();

            const { data } = await BookingService.getBookingAvailableKeywords(payload, abortController);

            for (const keywordView of data) {
                keywordViewMap.set(keywordView.id, keywordView);
            }
            loading.value = false;
        } catch (error) {
            if (error instanceof CanceledError || error instanceof yup.ValidationError) return;

            let message = 'Ошибка при обработки данных по ключевым словам';

            if (error instanceof BookingError) {
                message = error.message;
            }

            captureError(error, message);
            loading.value = false;
        }
    };

    watch(
        keywordIds,
        async currentKeywordIds => {
            if (!currentKeywordIds.every(keywordId => keywordViewMap.has(keywordId))) {
                await getBookingAvailableKeywords();
            }
        },
        { immediate: true }
    );

    return {
        loading,
        keywordsPrice,
        isKeywordUsed,
        getKeywordPrice,
        getKeywordAverage
    };
};
