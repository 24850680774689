import type { Staff, StaffId } from '../model/Staff';
import client from '@/shared/api/client';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';

export interface StaffQueryParams extends ServerPagination {
    fullName?: string;
}

export const getStaffs = async (params: StaffQueryParams, abortController = new AbortController()) => {
    const { data } = await client.get<{ data: Staff[]; meta: Pagination }>('/api/v1/staff', {
        params: {
            fullName: params.fullName,
            page: params.currentPage,
            limit: params.itemsPerPage
        },
        signal: abortController.signal
    });

    return {
        data: data.data,
        pagination: data.meta
    };
};

export const getStaffById = async (staffId: StaffId, abortController = new AbortController()) => {
    const { data } = await client.get<Staff>(`/api/v1/staff/${staffId}`, {
        signal: abortController.signal
    });

    return data;
};
