import type { Brand } from '@/shared/model/types/Kernel';

export type PermissionId = Brand<number, 'permission'>;
export type PermissionGroupId = Brand<number, 'permissionGroup'>;

/**
 * Сгруппированный доступы
 */

export enum PermissionGroupDictionary {
    /**
     * Передача прав от менеджера к поставщику
     */
    GROUP_ALLOW_TRANSFER_MANAGER_TO_SUPPLIERS = 'GROUP_ALLOW_TRANSFER_MANAGER_TO_SUPPLIERS',
    /**
     * Группа асинхронных отчетов
     */
    GROUP_REPORTS = 'GROUP_REPORTS',

    /**
     * Группа для рекламных размещений
     */
    PLACEMENT_PERMISSION_GROUP = 'PLACEMENT_PERMISSION_GROUP'
}

export type PermissionGroupKey = keyof typeof PermissionGroupDictionary;

export interface PermissionGroup {
    key: PermissionGroupKey;
    permissionIds: PermissionId[];
}

/**
 * Доступы пользователя
 */
export enum PermissionDictionary {
    /**
     * Администратор пользователей в своей компании
     */
    USERS_ADMIN_OWN_COMPANY = 'USERS_ADMIN_OWN_COMPANY',

    /**
     * Администратор пользователей в партнерской компании
     */
    USERS_ADMIN_PARTNER_COMPANY = 'USERS_ADMIN_PARTNER_COMPANY',

    /**
     * Возможность использовать имперсонацию пользователя
     */
    IMPERSONATION_ALLOWED = 'IMPERSONATION_ALLOWED',

    /**
     *  Просмотр профиля менеджера менеджером в режиме имперсонации
     */
    VIEW_MANAGER_PROFILE_BY_MANAGER = 'VIEW_MANAGER_PROFILE_BY_MANAGER',

    /**
     * Раздел рекламные размещения
     */
    ADVERTISING_PLACEMENTS = 'ADVERTISING_PLACEMENTS',

    /**
     * Отчет товарный запас по филиалам
     */
    REPORT_WHSTOCK = 'REPORT_WHSTOCK',

    /**
     * Доступ к разделу поставщиков
     */
    SUPPLIERS = 'SUPPLIERS',

    /**
     * Отчет по рекламным размещениям
     */
    REPORT_ADVERTISING_PLACEMENTS = 'REPORT_ADVERTISING_PLACEMENTS',

    /**
     * Фильтр по компаниям раздел Рекламные кампании
     */
    FILTER_SUPPLIER_ADVERTISING_CAMPAIGNS = 'FILTER_SUPPLIER_ADVERTISING_CAMPAIGNS',

    /**
     * Видимость списка поставщиков в отчётах
     */
    SUPPLIERS_LIST_VISIBLE = 'SUPPLIERS_LIST_VISIBLE',

    /**
     * Отчет отгрузка по клиентам по дням (b2c)
     */
    REPORT_SHIPPARTNER_B2C = 'REPORT_SHIPPARTNER_B2C',

    /**
     * Отчет отгрузка по клиентам по месяцам (b2c)
     */
    REPORT_SHIPPARTNER_MONTH_B2C = 'REPORT_SHIPPARTNER_MONTH_B2C',

    /**
     * Отчет отгрузка по клиентам по дням (b2b)
     */
    REPORT_SHIPPARTNER_B2B = 'REPORT_SHIPPARTNER_B2B',

    /**
     * Отчет отгрузка по клиентам по месяцам (b2b)
     */
    REPORT_SHIPPARTNER_MONTH_B2B = 'REPORT_SHIPPARTNER_MONTH_B2B',

    /**
     * Отчет отгрузка по сбытовым подразделениям по дням (b2c)
     */
    REPORT_SHIPDEPS_B2C = 'REPORT_SHIPDEPS_B2C',

    /**
     * Отчет отгрузка по сбытовым подразделениям по месяцам (b2b)
     */
    REPORT_SHIPDEPS_B2B = 'REPORT_SHIPDEPS_B2B',

    /**
     * Отчет отгрузка по сбытовым подразделениям по дням (b2b)
     */
    REPORT_SHIPDEPS_MONTH_B2B = 'REPORT_SHIPDEPS_MONTH_B2B',

    /**
     * Отчет отгрузка по сбытовым подразделениям по месяцам (b2c)
     */
    REPORT_SHIPDEPS_MONTH_B2C = 'REPORT_SHIPDEPS_MONTH_B2C',

    /**
     * Отчет отгрузка по регионам и городам по дням (b2c)
     */
    REPORT_SHIPCITY_B2C = 'REPORT_SHIPCITY_B2C',

    /**
     * Отчет отгрузка по регионам и городам по месяцам (b2c)
     */
    REPORT_SHIPCITY_MONTH_B2C = 'REPORT_SHIPCITY_MONTH_B2C',

    /**
     * Отчет отгрузка по регионам и городам по дням (b2b)
     */
    REPORT_SHIPCITY_B2B = 'REPORT_SHIPCITY_B2B',

    /**
     * Отчет отгрузка по регионам и городам по месяцам (b2b)
     */
    REPORT_SHIPCITY_MONTH_B2B = 'REPORT_SHIPCITY_MONTH_B2B',

    /**
     * Отчет отгрузка по клиентам по дням - Общий отчет"
     */
    REPORT_SHIPPARTNER_GENERAL = 'REPORT_SHIPPARTNER_GENERAL',

    /**
     * Отчет отгрузка по клиентам по месяцам - Общий отчет
     */
    REPORT_SHIPPARTNER_MONTH_GENERAL = 'REPORT_SHIPPARTNER_MONTH_GENERAL',

    /**
     * Отчет отгрузка по сбытовым подразделениям по дням - Общий отчет
     */
    REPORT_SHIPDEPS_GENERAL = 'REPORT_SHIPDEPS_GENERAL',

    /**
     * Отчет отгрузка по сбытовым подразделениям по месяцам - Общий отчет"
     */
    REPORT_SHIPDEPS_MONTH_GENERAL = 'REPORT_SHIPDEPS_MONTH_GENERAL',

    /**
     * Отчет отгрузка по регионам и городам по дням - Общий отчет
     */
    REPORT_SHIPCITY_GENERAL = 'REPORT_SHIPCITY_GENERAL',

    /**
     * Отчет отгрузка по регионам и городам по месяцам - Общий отчет
     */
    REPORT_SHIPCITY_MONTH_GENERAL = 'REPORT_SHIPCITY_MONTH_GENERAL',

    /**
     * Видимость вкладки дополнительно (раздел создания РК)
     */
    AD_ADDITIONAL_TAB = 'AD_ADDITIONAL_TAB',

    /**
     * Видимость toggle, корректировка статистики
     */
    TOGGLE_VISIBLE_ADJUSTMENT = 'TOGGLE_VISIBLE_ADJUSTMENT',

    /**
     * Видимость раздела настройки ЛК
     */
    OPTIONS_SECTION = 'OPTIONS_SECTION',

    /**
     * Видимость раздела Заказы
     */
    ORDERS_SECTION = 'ORDERS_SECTION',

    /**
     * Видимость вкладки Дополнительно в разделе регистрации поставщика
     */
    ADDITIONAL_TAB_VENDOR_REGISTRATION = 'ADDITIONAL_TAB_VENDOR_REGISTRATION',

    /**
     * Видимость блока Скидка
     */
    BLOCK_DISCOUNT = 'BLOCK_DISCOUNT',

    /**
     * Видимость блока Информация о рекламодателе
     */
    ADVERTISER_INFORMATION = 'ADVERTISER_INFORMATION',

    /**
     * Видимость раздела Товары
     */
    GOODS_SECTION = 'GOODS_SECTION',

    /**
     * Фильтр по компаниям в разделе Заказы
     */
    FILTER_SUPPLIER_SECTION_ORDERS = 'FILTER_SUPPLIER_SECTION_ORDERS',

    /**
     * Видимость вкладки Дополнительно в разделе акции
     */
    ADDITIONAL_TAB_PROMOTIONS_SECTION = 'ADDITIONAL_TAB_PROMOTIONS_SECTION',

    /**
     * Видимость раздела Товары - Настройка категорий
     */
    SECTION_SETTING_CATEGORIES = 'SECTION_SETTING_CATEGORIES',

    /**
     * Фильтр по компаниям в разделе Товары
     */
    FILTER_SUPPLIER_SECTION_GOODS = 'FILTER_SUPPLIER_SECTION_GOODS',

    /**
     * Видимость вкладки Параметры рекламы в профиле поставщика
     */
    ADVERTISING_OPTIONS_IN_THE_VENDOR_PROFILE = 'ADVERTISING_OPTIONS_IN_THE_VENDOR_PROFILE',

    /**
     * Видимость вкладки Цены
     */
    PRICES = 'PRICES',

    /**
     * Отчет товарный запас по филиалам - 1-е число месяца
     */
    REPORT_WHSTOCK_FIRST_DAY_OF_THE_MONTH = 'REPORT_WHSTOCK_FIRST_DAY_OF_THE_MONTH',

    /**
     * Отчет товарный запас по филиалам - каждый понедельник недели
     */
    REPORT_WHSTOCK_EVERY_MONDAY_OF_THE_WEEK = 'REPORT_WHSTOCK_EVERY_MONDAY_OF_THE_WEEK',

    /**
     * Сброс пароля администратором
     */
    ADMINISTRATOR_PASSWORD_RESET = 'ADMINISTRATOR_PASSWORD_RESET',

    /**
     * Фильтр по компаниям в разделе Цены
     */
    FILTER_SUPPLIER_SECTION_PRICE = 'FILTER_SUPPLIER_SECTION_PRICE',

    /**
     * Возможность добавлять новые ключевые слова в справочник
     */
    ADDING_NEW_WORDS_TO_THE_DICTIONARY = 'ADDING_NEW_WORDS_TO_THE_DICTIONARY',

    /**
     * Отчет по акциям Протек (B2B)
     */
    PROTEK_STOCK_REPORTS = 'PROTEK_STOCK_REPORTS',

    /**
     * Фильтр по типу акций Здравсити (B2C)
     */
    FILTER_BY_STOCK_TYPE_ZDRAVCITY = 'FILTER_BY_STOCK_TYPE_ZDRAVCITY',

    /**
     * Раздел 'Корректировки по отгрузке'
     */
    SHIPPING_ADJUSTMENTS = 'SHIPPING_ADJUSTMENTS',

    /**
     * Управление отчетами закупочных подразделений
     */
    PURCHASING_DEPARTMENT_REPORT_MANAGEMENT = 'PURCHASING_DEPARTMENT_REPORT_MANAGEMENT',

    /**
     * Управление отчетами акций B2B
     */
    B2B_STOCK_REPORT_MANAGEMENTT = 'B2B_STOCK_REPORT_MANAGEMENTT',

    /**
     * Управление отчетами акций Здравсити
     */
    MANAGING_STOCK_REPORTS_HEALTHCITY = 'MANAGING_STOCK_REPORTS_HEALTHCITY',

    /**
     * Совместное планирование - План Протека
     */
    JOINT_PLANNING_PLAN_PROTEK = 'JOINT_PLANNING_PLAN_PROTEK',

    /**
     * Совместное планирование - План поставщика
     */
    JOINT_PLANNING_VENDOR_PLAN = 'JOINT_PLANNING_VENDOR_PLAN',

    /**
     * Управление отчетами ОСО
     */
    MANAGING_OSO_REPORTS = 'MANAGING_OSO_REPORTS',

    /** Отчет по акциям Здравсити (B2C): Комплект одноименный */
    ZDRAVCITY_STOCK_REPORTS_SET_SAME_NAME = 'ZDRAVCITY_STOCK_REPORTS_SET_SAME_NAME',

    /** Отчет по акциям Здравсити (B2C): Комплект разноименный */
    ZDRAVCITY_STOCK_REPORTS_SET_DIFFERENT_NAME = 'ZDRAVCITY_STOCK_REPORTS_SET_DIFFERENT_NAME',

    /** Отчет по акциям Здравсити (B2C): Промокод */
    ZDRAVCITY_STOCK_REPORTS_PROMOCODE = 'ZDRAVCITY_STOCK_REPORTS_PROMOCODE',

    /** Отчет по акциям Здравсити (B2C): Прямая скидка */
    ZDRAVCITY_STOCK_REPORTS_DIRECT_DISCOUNT = 'ZDRAVCITY_STOCK_REPORTS_DIRECT_DISCOUNT',

    /** Отчет по акциям Здравсити (B2C): Спец. дни */
    ZDRAVCITY_STOCK_SPECIAL_DAYS = 'ZDRAVCITY_STOCK_SPECIAL_DAYS',

    /** Отчет по продажам рекламных товаров */
    REPORT_AD_GOODS = 'REPORT_AD_GOODS',

    /** Трансформировать возвраты в отгрузку */
    TRANFORM_RETURNS_INTO_SHIPMENTS = 'TRANFORM_RETURNS_INTO_SHIPMENTS',

    /** Признак  B2B/B2C в общем отчете */
    TYPE_ORDER_B2B_OR_B2C = 'TYPE_ORDER_B2B_OR_B2C',

    /** Исключение новых территорий */
    EXCLUSION_OF_NEW_TERRITORIES = 'EXCLUSION_OF_NEW_TERRITORIES',

    /**
     * Е-поставка обязательное подтверждение тендерной детализации
     */
    CONFIRMATION_OF_TENDER_DETAILS = 'CONFIRMATION_OF_TENDER_DETAILS',

    /**
     * Лента активности бронирований
     */
    BOOKING_ACTIVITY = 'BOOKING_ACTIVITY',

    /**
     * Детализация по документам - отгрузка по клиентам
     */
    REPORT_SHIPPARTNER_GENERAL_DOC_DETAILS = 'REPORT_SHIPPARTNER_GENERAL_DOC_DETAILS',

    /**
     * Отчёты по отгрузке - Выделение клиента "Ригла" в отчетности
     */
    REPORT_RIGLA_HIGHLIGHT = 'REPORT_RIGLA_HIGHLIGHT'
}

export enum PlacementPermission {
    /**
     * Баннер на главной
     */
    BANNER_ON_MAINPAGE = 'BANNER_ON_MAINPAGE',

    /**
     * Баннер в поисковой выдаче
     */
    BANNER_IN_SEARCH_RESULTS = 'BANNER_IN_SEARCH_RESULTS',

    /**
     * Баннер в подкатегории
     */
    BANNER_SUBCATEGORY = 'BANNER_SUBCATEGORY',

    /**
     * Баннер в карточке с привязкой к категории
     */
    BANNER_IN_THE_CARD_WITH_A_LINK_TO_THE_CATEGORY = 'BANNER_IN_THE_CARD_WITH_A_LINK_TO_THE_CATEGORY',

    /**
     * Контекстная реклама
     */
    CONTEXTUAL_ADVERTISING = 'CONTEXTUAL_ADVERTISING',

    /**
     * Приоритетная выдача по МНН (1-й блок)
     */
    PRIORITY_ISSUANCE_OF_MNN_1ST_BLOCK = 'PRIORITY_ISSUANCE_OF_MNN_1ST_BLOCK',

    /**
     * Приоритетная выдача по МНН (2-й блок)
     */
    PRIORITY_ISSUANCE_OF_MNN_2ST_BLOCK = 'PRIORITY_ISSUANCE_OF_MNN_2ST_BLOCK',

    /**
     * Товар дня
     */
    PRODUCT_OF_THE_DAY = 'PRODUCT_OF_THE_DAY',

    /**
     * Топ-позиции в подкатегории
     */
    TOP_POSITIONS_IN_THE_SUBCATEGORY = 'TOP_POSITIONS_IN_THE_SUBCATEGORY',

    /**
     * Наши партнеры
     */
    OUR_PARTNERS = 'OUR_PARTNERS',

    /**
     * Размещение - Большой баннер на странице бренда
     */
    LARGE_BANNER_ON_BRANDPAGE = 'LARGE_BANNER_ON_BRANDPAGE',

    /**
     * Размещение - Средний баннер на странице бренда
     */
    MEDIUM_BANNER_ON_BRANDPAGE = 'MEDIUM_BANNER_ON_BRANDPAGE',

    /**
     * Размещение - Маленький баннер на странице бренда
     */
    SMALL_BANNER_ON_BRANDPAGE = 'SMALL_BANNER_ON_BRANDPAGE'
}

export type PermissionDictionaryUnion = PermissionDictionary | PlacementPermission;
export type PermissionKey = keyof typeof PermissionDictionary | PlacementPermission;

export interface Permission {
    id: PermissionId;
    title: string;
    key: PermissionKey;
    description: string;
    visible: boolean;
}
