import Analytics from './Analytics';
import type { EventBase } from './types';
import type { CompanyId } from '@/shared/model/types/Company';
import { useSupplierStore } from '@/entities/Supplier/model/SupplierStore';
import type { SupplierId } from '@/entities/Supplier/model/Supplier';
import { logger } from '@/shared/model/utils';

export const login = (payload: {
    status: 'success' | 'fail';
    userId?: number;
    companyId?: CompanyId;
    companyName?: string;
    userName?: string;
    email?: string;
}): void => {
    const event: EventBase = {
        action: 'login',
        ...payload
    };
    Analytics.send(event);
};

export const registration = (payload: { type: 'Резидент РФ' | 'ИП' | 'Нерезидент РФ' }): void => {
    Analytics.send({
        action: 'user_registration',
        type: payload.type
    });
};

interface CreateReportPayload {
    reportName: string;
    reportType: string;
    reportWabcCode?: SupplierId;
    reportStartDate: string;
    reportEndDate?: string;
}
export const createReport = async (_payload: CreateReportPayload): Promise<void> => {
    try {
        const payload: Record<string, unknown> = { ..._payload };
        if (_payload.reportWabcCode) {
            const supplierStore = useSupplierStore();
            const supplier = await supplierStore.fetchSupplierCached(_payload.reportWabcCode);
            payload.reportCompanyName = supplier.vendorName;
        }

        Analytics.send({
            action: 'create_report',
            ...payload
        });
    } catch (error) {
        logger.error(error);
    }
};

export const click = (target: string, payload?: Record<string, unknown>): void => {
    Analytics.send({
        action: 'click',
        target,
        ...payload
    });
};

export default {
    login,
    registration,
    click,
    createReport
};
