import type { CompanyId } from '@/shared/model/types/Company';

export const SENTRY_DSN: string | undefined = import.meta.env.VITE_SENTRY_DSN;
export const YM_ACCOUNT_ID: string | undefined = import.meta.env.VITE_YM_ACCOUNT_ID;
export const AMPLITUDE_API_KEY: string | undefined = import.meta.env.VITE_AMPLITUDE_API_KEY;
export const POSTHOG_API_KEY: string | undefined = import.meta.env.VITE_POSTHOG_API_KEY;
export const PROTEK_ID = Number(import.meta.env.VITE_PROTEK_ID) as CompanyId;
export const IS_PRODUCTION = import.meta.env.PROD;
export const ANALYTICS_DEBUG = import.meta.env.VITE_ANALYTICS_DEBUG === 'true';
export const MODE = import.meta.env.MODE ?? 'local';
