import client from '@/shared/api/client';
import type { Supplier, SupplierId } from '../model/Supplier';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';

export interface SupplierResponse {
    data: {
        full_name: string;
        vendor_name: string;
        wabc_code: SupplierId;
    }[];
    total: number;
}

export interface SuppliersParams extends Partial<ServerPagination> {
    search?: string | number;
}

export async function getSuppliers(
    payload: SuppliersParams,
    abortController?: AbortController
): Promise<{ data: Supplier[]; pagination: Pagination }> {
    const { search, itemsPerPage = 10, currentPage = 1 } = payload;

    const query = new URLSearchParams({
        limit: itemsPerPage.toString(),
        page: currentPage.toString(),
        ...(search && { search: search.toString() })
    });

    const {
        data: { data, total }
    } = await client.get<SupplierResponse>(`/api/handbooks/providers?${query}`, { signal: abortController?.signal });

    const suppliers = data.map(supplier => ({
        fullName: supplier.full_name,
        vendorName: supplier.vendor_name,
        wabcCode: supplier.wabc_code
    }));

    const totalItems = Number(total);
    const pagination: Pagination = {
        totalItems,
        currentPage,
        itemsPerPage,
        currentPageItemsCount: suppliers.length,
        totalPages: Math.ceil(totalItems / itemsPerPage)
    };

    return {
        data: suppliers,
        pagination
    };
}
