<template>
    <div class="card" :style="getStylePadding">
        <!-- @slot default slot -->
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
    /**
     * Размеры внутреннего отступа
     */
    padding?: string;

    /**
     * Размеры box-shadow
     */
    shadow?: string;
}

const props = withDefaults(defineProps<Props>(), {
    padding: '3.2rem',
    shadow: '0 6px 60px rgba(9, 29, 52, 0.03)'
});

const getStylePadding = computed(() => {
    return {
        '--padding-card-custom': props.padding,
        '--shadow-card-custom': props.shadow
    };
});
</script>

<style lang="scss" scoped>
.card {
    background-color: var(--background-card-custom, #fff);
    box-shadow: var(--shadow-card-custom, var(--shadow-card));
    padding: var(--padding-card-custom, 3.2rem);
    border-radius: var(--border-radius, var(--border-radius-8));
}
</style>
