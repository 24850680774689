export const getFileNameByUrl = (url: string): string => {
    try {
        const urlInstance = new URL(url);
        const file = new URLSearchParams(urlInstance.search);
        const fullFileName = file.get('filename');

        if (fullFileName) {
            return fullFileName.replace(/^\d+\./, '');
        }

        const pathParts = urlInstance.pathname.split('/');
        const lastPart = pathParts[pathParts.length - 1];

        return lastPart.replace(/^\d+\./, '') || url;
    } catch (_) {
        return url;
    }
};
