import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type {
    Permission,
    PermissionKey,
    PermissionGroupKey as PermissionGroupKey,
    PermissionDictionaryUnion,
    PermissionId
} from '../types/Permission';
import { PermissionGroupDictionary } from '../types/Permission';
import { useUserStore } from '@/shared/model/store/UserStore';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';
import client from '@/shared/api/client';
import { ability } from '@/shared/lib/casl/casl';
import {
    getPermissions,
    getPermissionsReportPackage,
    getUserCompanyPermissions
} from '@/shared/api/modules/Permission';
import { RolesDictionary } from '@/shared/model/types/Role';
import { PROTEK_ID } from '@/shared/config/env';
import type { ReportPermission } from '@/entities/ReportPackage';

const concatPermissions = (
    permissions: Permission[],
    oldPermissions: Map<PermissionKey, Permission>
): Map<PermissionKey, Permission> => {
    const newPermissions = new Map<PermissionKey, Permission>();

    for (const permission of permissions) {
        newPermissions.set(permission.key, permission);
    }

    return new Map([...oldPermissions, ...newPermissions]);
};

export const useAccessStore = defineStore('access', () => {
    const userStore = useUserStore();

    const isInitializing = ref(false);

    const permissions = ref<Map<PermissionKey, Permission>>(new Map());
    const groups = ref<Map<PermissionGroupKey, Permission[]>>(new Map());

    const permissionsArray = computed<Permission[]>(() => [...permissions.value.values()]);
    const permissionsIdMap = computed(() => {
        return permissionsArray.value.reduce((acc, permission) => {
            acc.set(permission.id, permission);
            return acc;
        }, new Map<PermissionId, Permission>());
    });

    // пермиссии и роли юзера
    const permissionsUser = ref<Set<PermissionKey>>(new Set());
    const permissionsReport = ref<ReportPermission[]>([]);
    const permissionsReportKeys = computed<Set<PermissionKey>>(() => {
        return new Set([...permissionsReport.value.map(p => p.key)]);
    });

    const permissionsPlacement = computed<Permission[]>(() => {
        const group = groups.value.get(PermissionGroupDictionary.PLACEMENT_PERMISSION_GROUP);

        if (!group) return [];

        return group.filter(permission => permission && permissionsUser.value.has(permission.key));
    });

    const rolesUser = computed<Set<RolesDictionary>>(() => new Set(userStore.roles.map(r => r.key)));

    const isProtekCompany = computed(() => userStore.companyId === PROTEK_ID);
    const isAdmin = computed(() => rolesUser.value.has(RolesDictionary.ADMINISTRATOR_ROLE));
    const isManager = computed(() => isProtekCompany.value || rolesUser.value.has(RolesDictionary.MANAGER_ROLE));

    const checkPermission = (...keys: PermissionDictionaryUnion[]) =>
        keys.some(key => {
            return permissionsUser.value.has(key) || permissionsReportKeys.value.has(key);
        });

    const clear = () => {
        permissions.value.clear();
        groups.value.clear();

        permissionsUser.value.clear();
        permissionsReport.value = [];
    };

    const init = async () => {
        try {
            isInitializing.value = true;

            if (!userStore.isAuthorized) {
                return;
            }

            const params = await getPermissions();
            permissions.value = concatPermissions(params.permissions, permissions.value);

            groups.value = params.groups.reduce<Map<PermissionGroupKey, Permission[]>>((groups, group) => {
                const permissionGroups = group.permissionIds.map(id => permissionsIdMap.value.get(id)!);
                groups.set(group.key, permissionGroups);
                return groups;
            }, new Map());

            const userId = userStore.userId;
            const companyId = userStore.companyId;

            if (!userId || !companyId) {
                logger.warn('Пользователь не авторизован или не указана компания');
                return;
            }

            const _userPermissions = await getUserCompanyPermissions(userId, companyId);
            permissions.value = concatPermissions(_userPermissions, permissions.value);

            permissionsUser.value = _userPermissions.reduce<Set<PermissionKey>>((keys, permission) => {
                keys.add(permission.key);
                return keys;
            }, new Set());

            permissionsReport.value = await getPermissionsReportPackage(userId, companyId);

            const { data: rules } = await client('/api/v1/authorization/rules');
            ability.update(rules);

            isInitializing.value = false;
        } catch (error) {
            sentry.captureException(error);
            logger.error(error);
        } finally {
            isInitializing.value = false;
        }
    };

    return {
        isInitializing,
        groups,
        permissions,
        permissionsArray,
        permissionsReport,
        permissionsUser,
        checkPermission,
        init,
        clear,
        rolesUser,
        isProtekCompany,
        isAdmin,
        isManager,
        permissionsPlacement
    };
});
