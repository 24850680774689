import { type AxiosResponse } from 'axios';
import { NetworkError } from '@/errors/NetworkError';
import type { BookingId, BookingStatus } from '@/entities/Booking';
import type { KeywordId } from '@/entities/Keywords';
import type { CompanyId } from '@/shared/model/types/Company';
import type { PlacementId } from '@/entities/Placement';

export interface BookingExist {
    bookingId: BookingId;
    brand: string;
    companyId: CompanyId;
    month: number;
    placementId: PlacementId;
    status: BookingStatus;
    year: string;
}

export interface BookingCategoryError {
    type: 'MOORE_THAN_ONE_ROOTS' | 'ORPHAN_CATEGORIES' | 'CATEGORY_NOT_AVAILABLE';
    codes: string[];
}

export interface BookingBusyKeywordError {
    busyKeywordIds: KeywordId[];
}

export interface MultiBookingError {
    codes?: string[];
    type:
        | 'MOORE_THAN_ONE_ROOTS'
        | 'ORPHAN_CATEGORIES'
        | 'CATEGORY_NOT_AVAILABLE'
        | 'IMPRESSIONS_LIMIT_EXCEED'
        | 'INSUFFICIENT_NUMBER_OF_BINDINGS'
        | 'KEYWORD_NOT_AVAILABLE'
        | 'SAME_BOOKING_ALREADY_EXIST'
        | 'BINDING_PRICE_RANGE_NOT_FILLED';
}

export interface BindingKeywordsError {
    requestNumberOfBindings: number;
    minRequestBookingLimit: number;
}

const ERROR_TYPE = new Map<string, string>([
    ['PLACEMENT_BY_ID_NOT_FOUND', 'Размещение с указанным идентификатором не найдено'],
    ['PLACEMENT_WITHOUT_AD_ID', 'Размещение не привязано к рекламному объявлению'],
    ['AD_PLACEMENT_BY_AD_ID_NOT_FOUND', 'Рекламное размещение с указанным идентификатором объявления не найдено'],
    ['GET_AD_PLACEMENT_ERROR', 'Ошибка при получении данных размещения'],
    ['UNSUPPORTED_PLACEMENT_TYPE', 'Размещение не поддерживается для бронирования'],
    ['COMPANY_BY_COMPANY_ID_NOT_FOUND', 'Компания с указанным идентификатором не найдена'],
    ['BOOKING_NOT_FOUND', 'Бронирование не найдено'],
    ['ZDRAVCITY_CATEGORIES_NOT_FOUND', 'Категории Здравсити не найдены'],
    ['ZDRAVCITY_CATEGORIES_VALIDATION_ERROR', 'Ошибка валидации категорий Здравсити'],
    ['KEYWORDS_NOT_FOUND', 'Ключевые слова не найдены'],
    ['KEYWORDS_MISSING', 'Отсутствуют ключевые слова'],
    ['ADVERTISING_CAMPAIGN_BY_ID_NOT_FOUND', 'Рекламная кампания с указанным идентификатором не найдена'],
    ['SAME_BOOKING_ALREADY_EXIST', 'Бронирование с такими параметрами уже существует'],
    ['PERMISSION_DENIED', 'Доступ к запрашиваемым данным запрещен'],
    ['ACCESS_TO_FIELDS_FORBIDDEN', 'Нет доступа к данным бронирования'],
    ['CHANGE_STATUS_FORBIDDEN', 'Изменение статуса заблокировано'],
    ['UPDATE_IN_THIS_STATUS_FORBIDDEN', 'Изменение данных запрещено в текущем статусе'],
    ['PLACEMENT_NAME_IN_DOCUMENTS_NOT_FOUND', 'Название размещения в документах не найдено'],
    ['MISSING_SUBCATEGORIES_COUNT_ERROR', 'Отсутствует информация о количестве подкатегорий'],
    ['MISSING_ADVERTISING_PLAN', 'Не задан рекламный план'],
    ['IMPRESSIONS_LIMIT_EXCEED', 'Превышен лимит количества показов'],
    ['IMPRESSIONS_MISSING', 'Отсутствуют значения для количества показов'],
    ['CONFLICTING_PROPERTIES', 'Обнаружены конфликтующие параметры'],
    ['CATEGORY_NOT_AVAILABLE', 'Указанная категория недоступна'],
    ['KEYWORD_NOT_AVAILABLE', 'Указанное ключевое слово недоступно'],
    ['MULTI_CREATE_ERROR', 'Ошибка при создании мультибронирования'],
    ['MULTI_UPDATE_ERROR', 'Ошибка при обновлении мультибронирования'],
    ['BINDING_PRICE_RANGE_NOT_FILLED', 'Необходимо указать ценовые диапазоны для ключевых слов'],
    ['BINDING_PRICE_RANGE_NOT_FOUND', 'У данного размещения не заданы ценовые диапазоны'],
    ['PLACEMENT_PRICE_SET_NOT_FILLED', 'Для данного размещения не указана цена'],
    ['INSUFFICIENT_NUMBER_OF_BINDINGS', 'Не добавлено необходимое минимальное количество привязок'],
    ['DIRECT_FIELD_CHANGE_UNSUPPORTED', 'Прямое изменение этого поля не поддерживается'],
    ['KEYWORDS_UPDATE_FORBIDDEN', 'Обновление ключевых слов запрещено'],
    ['INCORRECT_END_DATE', 'Некорректная дата завершения'],
    ['INCORRECT_IMPRESSION', 'Значение количества показов должно быть кратно 1 миллиону']
]);

export const isBookingError = (error: unknown): error is BookingError => {
    return ERROR_TYPE.has((error as BookingError)?.response?.data.type);
};

export class BookingError extends NetworkError {
    readonly name = 'BookingError';

    constructor(
        readonly type: string,
        readonly response: AxiosResponse
    ) {
        const message = ERROR_TYPE.get(type) ?? `Неизвестный тип ошибки ${type}`;
        super(message, response);
    }

    getBookingExistData() {
        if (this.type !== 'SAME_BOOKING_ALREADY_EXIST') return;
        return this.response.data.message as BookingExist;
    }

    getMultiBookingErrors() {
        if (this.type !== 'MULTI_CREATE_ERROR') return;

        const errors = this.response.data.message.errors as { errors: MultiBookingError[] }[] | MultiBookingError[];
        const errorInfo = errors.at(0);
        let resultErrors: MultiBookingError[] = [];

        if (errorInfo && 'errors' in errorInfo && errorInfo?.errors?.length) {
            resultErrors = errorInfo.errors;
        } else {
            resultErrors = errors as MultiBookingError[];
        }

        const filteredErrors: MultiBookingError[] = [];

        for (const error of resultErrors) {
            filteredErrors.push(error);

            const isBreakCaseType = [
                'CATEGORY_NOT_AVAILABLE',
                'IMPRESSIONS_LIMIT_EXCEED',
                'INSUFFICIENT_NUMBER_OF_BINDINGS',
                'KEYWORD_NOT_AVAILABLE',
                'SAME_BOOKING_ALREADY_EXIST',
                'BINDING_PRICE_RANGE_NOT_FILLED'
            ].includes(error.type);

            if (isBreakCaseType) {
                break;
            }
        }

        return filteredErrors;
    }

    getBookingCategoryErrors() {
        if (this.type !== 'ZDRAVCITY_CATEGORIES_VALIDATION_ERROR') return;
        return this.response.data.message.errors as BookingCategoryError[];
    }

    getBookingBusyKeywordErrors() {
        if (this.type !== 'KEYWORD_NOT_AVAILABLE') return;
        return this.response.data.message as BookingBusyKeywordError;
    }

    getNumberBindingErrors() {
        if (this.type !== 'INSUFFICIENT_NUMBER_OF_BINDINGS') return;
        return this.response.data.message as BindingKeywordsError;
    }

    static isBookingError(error: unknown): error is BookingError {
        return ERROR_TYPE.has((error as BookingError)?.response?.data.type);
    }

    static getMessageByType(type: string) {
        return ERROR_TYPE.get(type) ?? type;
    }
}
