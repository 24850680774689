import type { BookingFieldsParams, MultiBookingParams } from './BookingService';
import type { Booking } from '@/entities/Booking';
import { omit } from 'lodash';
import { useAccessStore } from '@/shared/model/store/AccessStore';
import { usePlacementBinding, usePlacementStore } from '@/entities/Placement';

export const formatFilledFields = (params: Partial<BookingFieldsParams | MultiBookingParams>) => {
    return Object.entries(params).reduce(
        (acc, [key, value]) => {
            if (Array.isArray(value) && value.length) {
                acc[key] = value.map(String);
            } else if (value) {
                acc[key] = value;
            }

            return acc;
        },
        {} as Record<string, unknown>
    );
};

export const formatFields = (updateBookingData: Partial<Booking>) => {
    if (updateBookingData.productWabcCodes) {
        updateBookingData.productWabcCodes = updateBookingData.productWabcCodes?.map(product => ({
            guid: product.guid,
            kod_cvp: product.kod_cvp,
            name: product.name,
            url: product.url
        }));
    }

    const formatValue = (value: unknown) => {
        if (typeof value === 'string') {
            return value.trim() || null;
        }

        return value === undefined ? null : value;
    };

    return Object.fromEntries(Object.entries(updateBookingData).map(([key, value]) => [key, formatValue(value)]));
};

export const formatCreateFields = (booking: Partial<Booking>) => {
    const accessStore = useAccessStore();
    const isSupplier = !accessStore.isManager;

    return formatFields(
        omit(booking, [
            'id',
            'name',
            'prevStatus',
            'brandId',
            'campaignId',
            booking.priceWithNds === null || isSupplier ? 'priceWithNds' : '',
            'nds',
            'price',
            'advertisingDiscount',
            'allAccountingDocumentsFilled',
            'preset',
            'cancelPrice',
            'erId',
            'wabcCode',
            'createdAt',
            'updatedAt'
        ])
    );
};

export const formatUpdateFields = (booking: Partial<Booking>) => {
    const accessStore = useAccessStore();
    const isSupplier = !accessStore.isManager;

    const placementStore = usePlacementStore();
    const placement = placementStore.getPlacementById(booking.placementId!);
    const { isPriceRange } = usePlacementBinding(placement);

    return formatFields(
        omit(booking, [
            'id',
            'name',
            'prevStatus',
            'brandId',
            'companyId',
            'placementId',
            isSupplier || !booking.isPriceWithNdsOverridden ? 'priceWithNds' : '',
            isSupplier ? 'ordStatus' : '',
            isSupplier ? 'campaignId' : '',
            isSupplier ? 'loaded' : '',
            isSupplier ? 'isPriceWithNdsOverridden' : '',
            isPriceRange.value && isSupplier && booking.isPriceWithNdsOverridden ? 'keywords' : '',
            'nds',
            'price',
            'advertisingDiscount',
            'allAccountingDocumentsFilled',
            'preset',
            'cancelPrice',
            'erId',
            'wabcCode',
            'createdAt',
            'updatedAt'
        ])
    );
};
