<template>
    <label
        :class="[
            'control',
            {
                'control--focused': focused,
                'control--has-value': hasValue,
                'control--is-ghost': isGhost
            }
        ]"
        :disabled="disabled ? disabled : null"
    >
        <div class="control__wrapper">
            <div class="control__placeholder">
                <slot name="label">{{ label }}</slot>
            </div>
            <!-- @slot default slot replace input -->
            <slot>
                <input
                    :id="id"
                    class="control__element"
                    autocapitalize="none"
                    :autocomplete="autocomplete"
                    :type="type"
                    :placeholder="placeholder"
                    :value="modelValue"
                    :disabled="disabled ? disabled : undefined"
                    :readonly="readonly ? readonly : undefined"
                    data-testid="input"
                    @input="onInput"
                    @change="onChange"
                    @focus="onFocus"
                    @blur="onBlur"
                />
            </slot>
        </div>

        <div class="control__actions">
            <!-- @slot append slot -->
            <slot name="append"></slot>

            <PButton
                v-if="clearable && hasValue"
                class="control__clear-button"
                variant="text"
                icon="close"
                :disabled
                data-testid="clear"
                @click.stop.prevent="clearValue"
            />
        </div>
    </label>
</template>

<script lang="ts" setup>
import PButton from '@/shared/ui/PButton/PButton.vue';
import { computed, ref } from 'vue';
import { generateUUID } from '@/shared/model/utils';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modelValue?: any;
    type?: string;
    label?: string;
    placeholder?: string;
    visiblePassword?: boolean;
    isGhost?: boolean;
    clearable?: boolean;
    autocomplete?: string;
    readonly?: boolean;
    disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    type: 'text',
    label: '',
    placeholder: '',
    visiblePassword: false,
    isGhost: false,
    clearable: false,
    autocomplete: 'off',
    readonly: false,
    disabled: false
});

const emit = defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:modelValue', val?: any): void;
    (e: 'input', val: Event): void;
    (e: 'change', val: Event): void;
    (e: 'focus', val: Event): void;
    (e: 'blur', val: Event): void;
    (e: 'clear', val: Event): void;
}>();

const id = generateUUID();
const focused = ref<boolean>(false);

const hasValue = computed<boolean>(() => {
    const isEmpty: boolean = props.modelValue === null || props.modelValue === undefined || props.modelValue === '';
    return !isEmpty || !!props.placeholder;
});

const onInput = (event: Event) => {
    emit('update:modelValue', (event.target as HTMLInputElement).value);
    emit('input', event);
};

const onChange = (event: Event) => {
    emit('change', event);
};

const clearValue = (event: Event) => {
    emit('update:modelValue');
    emit('change', event);
    emit('clear', event);
};

const onFocus = (event: Event) => {
    focused.value = true;
    emit('focus', event);
};

const onBlur = (event: Event) => {
    focused.value = false;
    emit('blur', event);
};
</script>
