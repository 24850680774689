<template>
    <PInputMask
        v-model="value"
        :label="label"
        :type="typeControl"
        :placeholder="placeholder"
        :disabled="disabled"
        @blur="$emit('blur', $event)"
    >
        <template #append>
            <PButton
                variant="text"
                :aria-label="isVisiblePassword ? 'Скрыть пароль' : 'Показать пароль'"
                :icon="isVisiblePassword ? 'visibility-off' : 'visibility-on'"
                @click.stop.prevent="togglePassword"
            />
        </template>
    </PInputMask>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import PButton from '../PButton/PButton.vue';
import PInputMask from '../InputText/PInputMask.vue';

interface Props {
    /**
     * Строка для подстановки описания поля ввода
     */
    label?: string;

    /**
     * Строка для подстановки заглушки в поля ввода
     */
    placeholder?: string;

    /**
     * Блокировка состояния
     */
    disabled?: boolean;
}

defineOptions({
    name: 'PInputPassword'
});

withDefaults(defineProps<Props>(), {
    placeholder: '',
    label: 'Пароль',
    disabled: false
});

defineEmits<{
    (e: 'change', val: Event): void;
    (e: 'blur', val: Event): void;
}>();

const value = defineModel<string | null>();
const isVisiblePassword = ref(false);

const typeControl = computed(() => {
    return isVisiblePassword.value ? 'text' : 'password';
});

const togglePassword = () => {
    isVisiblePassword.value = !isVisiblePassword.value;
};
</script>
