import { pick } from 'lodash';
import client from '@/shared/api/client';
import type { Permission, PermissionGroup } from '@/shared/model/types/Permission';
import type { CompanyId } from '@/shared/model/types/Company';
import type { UserId } from '@/shared/model/types/User';
import type { ReportPermission } from '@/entities/ReportPackage';

export async function getPermissions(): Promise<{
    permissions: Permission[];
    groups: PermissionGroup[];
}> {
    const { data } = await client.get<{ data: Permission[]; groups: PermissionGroup[] }>('/api/v1/permissions');

    return {
        permissions: data.data,
        groups: data.groups
    };
}

export const getUserCompanyPermissions = async (userId: UserId, companyId: CompanyId): Promise<Permission[]> => {
    const response = await client.get(`/api/v1/users/${userId}/companies/${companyId}/permissions`);
    return response.data.data;
};

export const getPermissionsReportPackage = async (
    userId: UserId,
    companyId: CompanyId
): Promise<ReportPermission[]> => {
    const response = await client.get('/api/v1/report-packages/permissions', { params: { userId, companyId } });
    return response.data.map((item: unknown) => pick(item, ['startDate', 'endDate', 'key']));
};
