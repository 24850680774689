import { defineStore } from 'pinia';
import type { User } from '@/shared/model/types/User';
import { UserStatus } from '@/shared/model/types/User';
import sentry from '@/shared/lib/sentry/sentry';
import * as UserService from '@/shared/api/modules/User';
import type { Company, CompanyId } from '@/shared/model/types/Company';
import { computed, ref } from 'vue';
import type { UserProfile } from '@/shared/model/types/UserProfile';
import * as UserProfileService from '@/shared/api/modules/UserProfile';
import { getDataFromToken, getToken, impersonateToken } from '@/shared/model/TokenService';
import type { Role } from '@/shared/model/types/Role';
import Analytics from '@/shared/lib/analytics/Analytics';
import { PROTEK_ID } from '@/shared/config/env';
import { logger } from '@/shared/model/utils';
import { useStorage } from '@vueuse/core';
import { StaffService } from '@/entities/Staff';

export const useUserStore = defineStore('user', () => {
    const companies = ref<Company[]>([]);
    const activeCompanyId = ref<CompanyId>();
    const company = computed<Company | undefined>(() => {
        if (!activeCompanyId.value) {
            return;
        }
        return companies.value.find(c => c.id === activeCompanyId.value);
    });
    // id активной компании только для чтения
    const companyId = computed(() => activeCompanyId.value ?? null);
    const companyWabcCode = computed(() => company.value?.wabcCode ?? null);
    const user = ref<User>();
    const userId = computed(() => user.value?.id ?? null);
    const roles = ref<Role[]>([]);
    const isAuthorized = computed<boolean>(() => !!user.value);
    const userProfile = ref<UserProfile>();
    const userProfileId = computed(() => userProfile.value?.id ?? null);
    const isActiveImpersonateUser = ref<boolean>(!!impersonateToken.get());

    const isRequestChangePassword = useStorage('requestChangePassword', false);

    const fetchUser = async (companyId?: CompanyId) => {
        const token = getToken();

        if (!token) return;

        const { sub: userId, activeCompanyId: userActiveCompanyId } = getDataFromToken(token);

        const {
            companies: _companies,
            users,
            userProfiles,
            roles: _roles
        } = await UserService.getUsers({
            id: userId,
            companyId,
            includes: ['companies', 'profile', 'roles']
        });
        companies.value = _companies;
        roles.value = _roles;

        user.value = users.find(u => u.id);

        if (!user.value) {
            throw new Error('Юзер не найден');
        }

        activeCompanyId.value = companyId ?? userActiveCompanyId;
        [userProfile.value] = userProfiles;

        if (!userProfile.value) {
            logger.warn('У пользователя нет UserProfile');
        }

        if (!company.value) {
            logger.warn('Нет компании привязанной к пользователю');
        }

        Analytics.setUserId(userId);

        const userData: Record<string, unknown> = {
            userName: userProfile.value?.fullName,
            email: user.value?.email
        };

        const commercialManagerId = company.value?.commercialManagerId;
        if (companyId && companyId !== PROTEK_ID && commercialManagerId) {
            try {
                const manager = await StaffService.getStaffById(commercialManagerId);
                userData.managerId = commercialManagerId;
                userData.managerName = manager?.fullName ?? `Менеджер ${commercialManagerId}`;
            } catch (error) {
                logger.error(error);
            }
        }

        if (companyId) {
            userData.companyId = companyId;
        }

        if (company.value) {
            userData.companyName = company.value.name;
        }

        Analytics.setUserData(userData);

        sentry.setUser({
            id: userId
        });
    };

    const refreshUser = (companyId?: CompanyId) => {
        return fetchUser(companyId);
    };

    const changePassword = async (password: string) => {
        const userId = user.value?.id;

        if (!userId) {
            throw new Error('Нельзя обновить информацию по пользователю до его авторизации');
        }

        await UserService.changePasswordByUserId(password, userId);
        // TODO: неявное поведение, надо уточнить
        await UserService.updateUser(userId, {
            status: UserStatus.ACTIVE
        });
        isRequestChangePassword.value = false;
    };

    const updateProfile = async (payload: Partial<UserProfile>) => {
        const profileId = userProfile.value?.id;
        const userId = user.value?.id;

        if (!userId) {
            throw new Error('Нельзя обновить информацию по пользователю до его авторизации');
        }

        if (profileId) {
            await UserProfileService.updateUserProfile(profileId, payload);
        } else {
            const { userProfile } = await UserProfileService.createUserProfile(payload);
            await UserService.setUserRelationshipsToProfileId(userId, userProfile.id);
        }
    };

    const logout = async () => {
        companies.value = [];
        user.value = undefined;
        activeCompanyId.value = undefined;
        isRequestChangePassword.value = false;
    };

    return {
        fetchUser,
        companyId,
        companyWabcCode,
        userId,
        userProfileId,
        company,
        companies,
        user,
        userProfile,
        logout,
        isAuthorized,
        changePassword,
        refreshUser,
        updateProfile,
        isRequestChangePassword,
        isActiveImpersonateUser,
        roles
    };
});
