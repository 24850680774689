import { defineStore } from 'pinia';
import type { Pagination } from '@/shared/model/types/Pagination';
import { NotFoundError } from '@/errors/NotFoundError';
import type { Supplier, SupplierId } from './Supplier';
import { type SuppliersParams, getSuppliers } from '../api/SupplierService';

const cache = new Map<string, { data: Supplier[]; pagination: Pagination }>();

export const useSupplierStore = defineStore('supplier', {
    state: () => ({
        suppliers: {} as Record<SupplierId, Supplier>
    }),

    getters: {
        suppliersArray: state => Object.values(state.suppliers)
    },

    actions: {
        async fetchSuppliers(
            params: SuppliersParams,
            abortController?: AbortController
        ): Promise<{ data: Supplier[]; pagination: Pagination }> {
            const cacheKey = JSON.stringify(params ?? 'default');
            const cachedData = cache.get(cacheKey);

            if (cachedData) {
                return cachedData;
            }

            const response = await getSuppliers(params, abortController);

            for (const supplier of response.data) {
                this.suppliers[supplier.wabcCode] = supplier;
            }

            cache.set(cacheKey, response);

            return response;
        },
        async fetchSupplierCached(wabcCode: SupplierId, abortController?: AbortController): Promise<Supplier> {
            if (!this.suppliers[wabcCode]) {
                await this.fetchSuppliers(
                    {
                        search: wabcCode,
                        itemsPerPage: 100,
                        currentPage: 1
                    },
                    abortController
                );
            }

            const supplier = this.suppliers[wabcCode];

            if (!supplier) {
                throw new NotFoundError(`Поставщик ${wabcCode} не найден`);
            }

            return supplier;
        }
    }
});
