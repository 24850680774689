<template>
    <div class="centered">
        <PCard padding="5rem 4rem 5rem" shadow="var(--shadow-card-dark)" style="width: 390px">
            <PForm ref="formEl" class="grid-column" style="--gap: 3rem; --column: 1" @submit="submit">
                <h1>Войти</h1>

                <FormItem v-slot="{ field }" v-model="form.username" rules="required|email" name="username">
                    <InputText v-bind="field" label="Email" placeholder="Введите email" autofocus />
                </FormItem>

                <FormItem v-slot="{ field }" v-model="form.password" rules="required" name="password">
                    <PInputPassword v-bind="field" label="Пароль" placeholder="Введите пароль" />
                </FormItem>

                <PButton type="submit" :disabled="loading"> Войти </PButton>

                <div class="grid-column" style="--gap: 1rem; --column: 1">
                    <div>
                        <span>Нет аккаунта?</span>
                        <router-link :to="{ name: 'registration' }"> Зарегистрироваться </router-link>
                    </div>
                    <router-link :to="{ name: 'password' }"> Забыли пароль? </router-link>
                </div>
            </PForm>
        </PCard>
    </div>
</template>

<script lang="ts" setup>
import { FormItem, InputText, PButton, PCard, PForm, PInputPassword } from '@/shared/ui';

import { CompanyStatus } from '@/models';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { login } from '@/processes/Auth';
import { useUserStore } from '@/shared/model/store/UserStore';
import analyticEvents from '@/shared/lib/analytics/analytics_events';
import { logger } from '@/shared/model/utils';
import { useRememberLocationStore } from '@/shared/model/store/RememberLocationStore';
import { useStore } from '@/shared/model/store/Store';
import Analytics from '@/shared/lib/analytics/Analytics';

defineOptions({
    name: 'PageLogin'
});

const store = useStore();
const router = useRouter();
const userStore = useUserStore();
const loading = ref(false);
const form = ref({
    username: import.meta.env.VITE_USER_EMAIL ?? '',
    password: import.meta.env.VITE_USER_PASSWORD ?? ''
});
const formEl = ref<typeof PForm>();

const submit = async () => {
    if (!(await formEl.value?.validate())) {
        return;
    }

    loading.value = true;
    try {
        await login(form.value);
        const company = userStore.company;
        const isCompanyNotFound = !company;
        store.isInitApp = true;
        const isApplicationNotChecked = () => company?.status !== CompanyStatus.ACTIVE;
        const rememberLocationStore = useRememberLocationStore();
        if (isCompanyNotFound || isApplicationNotChecked()) {
            await router.push({ name: 'company-profile' });
        } else if (rememberLocationStore.rememberedLocation) {
            await router.push(rememberLocationStore.rememberedLocation);
        } else {
            await router.push({ name: 'profile' });
        }
        analyticEvents.login({
            status: 'success',

            userId: userStore.userId!,
            userName: userStore.userProfile?.fullName,
            email: form.value.username,

            companyId: userStore.companyId!,
            companyName: userStore.company?.name
        });

        Analytics.setUserInfo({
            userId: userStore.userId!,
            name: userStore.userProfile?.fullName ?? '',
            position: userStore.userProfile?.position ?? '',
            department: userStore.userProfile?.department ?? '',
            email: form.value.username,

            companyId: userStore.companyId!,
            companyName: userStore.company?.name ?? ''
        });
    } catch (error) {
        formEl.value?.setErrors({
            email: 'Неверный e-mail',
            password: 'Неверный пароль'
        });
        logger.error(error);
        analyticEvents.login({
            status: 'fail'
        });
    } finally {
        loading.value = false;
        store.isInitApp = false;
    }
};
</script>

<style scoped lang="scss">
.centered {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    text-align: center;
}

h1 {
    font-size: var(--text-size-24);
}
</style>
