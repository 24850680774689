import posthog from 'posthog-js';
import type { Router } from 'vue-router';
import { snakeCase } from 'lodash';
import { POSTHOG_API_KEY } from '@/shared/config/env';
import type { UserInfo } from '@/shared/lib/analytics/types';

type EventPosthog = Parameters<typeof posthog.capture>;

export default class Posthog {
    private static initiated = false;

    static init(): void {
        if (!POSTHOG_API_KEY) {
            return;
        }

        posthog.init(POSTHOG_API_KEY, {
            api_host: 'https://eu.i.posthog.com',
            person_profiles: 'identified_only',
            // т.к. мы отмечаем это событие вручную и наполняем доп. данными
            capture_pageview: false
        });

        Posthog.initiated = true;
    }

    static setUserId(id: string): void {
        if (!Posthog.initiated) {
            return;
        }

        posthog.identify(id);
    }

    static logEvent(...event: EventPosthog) {
        if (!Posthog.initiated) {
            return;
        }

        posthog.capture(...event);
    }

    static pageView(router: Router, payload: Record<string, unknown>) {
        if (!Posthog.initiated) {
            return;
        }

        const params: Record<string, unknown> = { ...payload };
        for (const [key, value] of Object.entries(router.currentRoute.value.params)) {
            params[snakeCase(key)] = String(value);
        }
        posthog.capture('$pageview', {
            ...params,
            route_name: router.currentRoute.value.name,
            page_title: router.currentRoute.value.meta?.title || document.title,
            page_location: window.location.href,
            page_path: window.location.pathname
        });
    }

    static logout() {
        if (!Posthog.initiated) {
            return;
        }

        posthog.reset();
    }

    static setUserInfo(profile: UserInfo) {
        if (!Posthog.initiated) {
            return;
        }

        posthog.identify(String(profile.userId), profile);
    }
}
