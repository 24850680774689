import { type CampaignTypes } from '@/entities/Campaign';
import type { CompanyId } from '@/shared/model/types/Company';
import type { ObjectSchema } from 'yup';
import { array, boolean, number, object, string } from 'yup';

export enum CustomerRole {
    DISTRIBUTOR = 'DISTRIBUTOR',
    OPERATOR = 'OPERATOR',
    AGENCY = 'AGENCY',
    ADVERTISER = 'ADVERTISER'
}

export enum PerformerRole {
    DISTRIBUTOR = 'DISTRIBUTOR',
    OPERATOR = 'OPERATOR',
    AGENCY = 'AGENCY',
    ADVERTISER = 'ADVERTISER'
}

export enum CampaignActStatisticType {
    SITE = 'SITE',
    APPLICATION = 'APPLICATION',
    INFORMATION_SYSTEM = 'INFORMATION_SYSTEM'
}

export interface CampaignActStatistic {
    type: CampaignActStatisticType;
    campaignId: CampaignTypes.CampaignId;
    companyName: string;
    campaignName: string;
    initialContractId: string;
    finalContractId: string;
    erId: string;
    url: string;
    platform: string;
    agency: boolean;
    impsPlan: number | null;
    impsFact: number | null;
    startDatePlan: string;
    startDateFact: string;
    endDatePlan: string;
    endDateFact: string;
    amount: number;
    price: number | null;
    vatIncluded: boolean;
}

export const schemaCampaignActStatistic: ObjectSchema<CampaignActStatistic> = object({
    type: string<CampaignActStatisticType>().required(),
    campaignId: number<CampaignTypes.CampaignId>().required(),
    initialContractId: string().defined(),
    finalContractId: string().defined(),
    erId: string().required(),
    url: string().required(),
    platform: string().max(100).required(),
    agency: boolean().required(),
    impsPlan: number().required(),
    impsFact: number().required(),
    startDatePlan: string().lessThanTodayOrEqual().required(),
    startDateFact: string().lessThanTodayOrEqual().required(),
    endDatePlan: string().isSameOrAfter('startDatePlan', 'Не может быть раньше даты плановых показов').required(),
    endDateFact: string().isSameOrAfter('startDateFact', 'Не может быть раньше даты фактических показов').required(),
    amount: number().required(),
    price: number().nullable().required(),
    vatIncluded: boolean().required(),
    companyName: string()
        .transform(value => (value === null ? 'Не указано' : value))
        .required(),
    campaignName: string().required()
});

export enum CampaignActType {
    SUPPLIER = 'PROVIDER',
    SELF_ADVERTISEMENT = 'SELF_ADVERTISEMENT',
    AGENCY = 'AGENCY'
}

export type CampaignActId = number;
export interface CampaignAct {
    id: CampaignActId;
    companyId: CompanyId;
    companyName: string;
    customerRole: CustomerRole;
    performerRole: PerformerRole;
    statistics: CampaignActStatistic[];
    number: string;
    date: string;
    costServices: number;
    nds: boolean;
    servicesStartDate: string;
    servicesEndDate: string;
    finalContractId: string;
    ordReportStatus: CampaignTypes.CampaignActReportStatus;
    type: CampaignActType;
}

export const schemaCampaignAct: ObjectSchema<Omit<CampaignAct, 'ordReportStatus' | 'companyName'>> = object({
    id: number().required(),
    companyId: number<CompanyId>().required(),
    customerRole: string<CustomerRole>().required(),
    performerRole: string<PerformerRole>().required(),
    statistics: array().of(schemaCampaignActStatistic).required(),
    number: string().required(),
    date: string().lessThanTodayOrEqual().required(),
    costServices: number().required(),
    nds: boolean().required(),
    servicesStartDate: string().lessThanTodayOrEqual().required(),
    servicesEndDate: string()
        .lessThanTodayOrEqual()
        .isSameOrAfter('servicesStartDate', 'Не может быть до даты начала оказания услуг')
        .required(),
    finalContractId: string().required(),
    type: string()
        .oneOf([CampaignActType.SELF_ADVERTISEMENT, CampaignActType.SUPPLIER, CampaignActType.AGENCY])
        .required()
});
