import type { EventBase } from '@/shared/lib/analytics/types';
import { YM_ACCOUNT_ID } from '@/shared/config/env';

export default class Metrika {
    private static id: string = YM_ACCOUNT_ID ?? '';

    private static initiated = false;

    static init(): void {
        if (Metrika.id && window.ym) {
            Metrika.initiated = true;
        }
    }

    static send({ action, ...payload }: EventBase): void {
        if (!Metrika.initiated) {
            return;
        }
        window.ym(Metrika.id, 'reachGoal', action, payload);
    }

    static setUserId(id: string | null): void {
        if (!Metrika.initiated) {
            return;
        }
        window.ym(Metrika.id, 'setUserId', id);
    }

    static pageView(): void {
        if (!Metrika.initiated) {
            return;
        }
        window.ym(Metrika.id, 'hit', window.location.pathname);
    }

    static logout() {
        Metrika.setUserId(null);
    }
}
