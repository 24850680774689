import { ref } from 'vue';
import { defineStore } from 'pinia';

export const initLogoutEventHandler = ({
    logout,
    openLoginPage,
    rememberLocation,
    cleanup
}: {
    logout: () => Promise<void>;
    openLoginPage: () => Promise<void>;
    cleanup: () => void;
    rememberLocation: () => void;
}) => {
    document.addEventListener('logout', async () => {
        rememberLocation();
        await logout();

        if (window.location.pathname !== '/login') {
            await openLoginPage();
        }

        cleanup();
    });
};

export const useRememberLocationStore = defineStore('rememberLocation', () => {
    const rememberedLocation = ref<string | null>(null);

    const remember = () => {
        const ignorePaths = ['/login', '/', '/forbidden', '/downtime'];
        if (!ignorePaths.includes(window.location.pathname)) {
            const relativeUrl = window.location.href.replace(window.location.origin, '');
            rememberedLocation.value = relativeUrl;
        }
    };

    return {
        remember,
        clear() {
            rememberedLocation.value = null;
        },
        rememberedLocation
    };
});
